
























































































import Vue from "vue";
import {
  mdiWeb,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiYoutube,
} from "@mdi/js";

import BlockRenderer from "@/components/BlockRenderer.vue";
import { Event } from "../store/types";

import { getUrl, OpenSocialLink } from "@/helpers";

interface Data {
  event: Event | null;
  loading: boolean;
  mdiWeb: string;
  mdiFacebook: string;
  mdiInstagram: string;
  mdiTwitter: string;
  mdiYoutube: string;
}

export default Vue.extend({
  name: "Event",
  components: {
    BlockRenderer,
  },
  mounted() {
    this.Load();
  },
  data(): Data {
    return {
      event: null,
      loading: false,
      mdiWeb,
      mdiFacebook,
      mdiInstagram,
      mdiTwitter,
      mdiYoutube,
    };
  },
  methods: {
    async Load() {
      const eventId = this.$route.params.id;

      if (this.loading === true) {
        return;
      }

      this.loading = true;
      const event = await this.$store.dispatch("directorio/GetEvent", eventId);
      if (event) {
        this.event = event;
        this.event.start_date = new Date(this.event.start_date);
        this.event.end_date = new Date(this.event.end_date);
        this.$refs.br.SetBlocks(this.event.blocks.blocks);
      }
      this.loading = false;
    },
    OpenSocialLink,
    FormatDate(date: Date) {
      return date.toLocaleString("es-CO", {
        day: "numeric",
        month: "short",
        year: "numeric",
        // hour: "2-digit",
        // minute: "2-digit",
      });
    },
    getUrl,
  },
});
